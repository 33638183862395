<template>
    <metabase type="question" width="100%" height="880px" resource="140" :params="{ }" />
</template>

<script>
import Metabase from '@/components/Metabase.vue';

export default {
    components: {
        Metabase
    }    
}
</script>

<style lang="scss" scoped>

</style>